import * as React from "react"
import Layout from "../components/layout/"
import { Container, Row, Col} from "react-bootstrap"
import { Helmet } from 'react-helmet';

// markup
const IndexPage = () => {


  return (
    <Layout>
      <Helmet>
        <title>Unternehmensgruppe Urbas</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12} className="overflow-hidden" style={{height: '40vh'}}>
            <div className="bg-dark-primary parallelogram-header1">
              <div className="unskew-header1">
                <div id="rect">
                  <div className="p-3">
                    <span className="font-size-xs d-inline-block">Dienstleister seit über 30 Jahren</span>
                    <h1>
                      IT-Lösungen
                      <br />
                    </h1>
                    <span>Professionelle Beratung und Betreuung für Ihre IT-Anliegen</span>
                    <br></br>
                    <a href="mailto:service@urbas.de">
                      <button className="btn btn-primary my-2">
                        Jetzt Termin vereinbaren
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col style={{overflow: 'hidden'}}>
            <div className="bg-dark-primary parallelogram-header2">
              <div className="unskew-header2">
                <BackgroundImage
                  Tag="section"
                  style={{height: '60vh'}}
                  fluid={imageData}
                  backgroundColor={`#040e18`}
                >
                </BackgroundImage>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
      {/* <Container className="pt-5" style={{maxWidth: 800}}>
          <Row>
            <Col xs="3" md="4" className="mb-3 mb-md-0 text-center">
                <Linkedin size={40} style={{color: 'grey'}} />
            </Col>
            <Col xs="3" md="4" className="mb-3 mb-md-0 text-center">
                <Facebook size={40} style={{color: 'grey'}} />
            </Col>
            <Col xs="3" md="4" className="mb-3 mb-md-0 text-center">
                <Instagram size={40} style={{color: 'grey'}} />
            </Col>
          </Row>
      </Container> */}
      <Container className="pt-3 p-0">
        <div className="bg-ultralight m-1">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
            <Row>
              <Col>
                <p className="col-12 description font-primary p-0">
                  Was wir machen
                </p>
                <h2>Ihr Partner für</h2>
              </Col>
            </Row>
            {/* <Row>
              <Col  md={{span: 8, offset: 2}} className="py-2">
                <Img
                  className="rounded-lg"
                  fluid={data.team.childImageSharp.fluid}
                  alt="Gatsby Docs are awesome"></Img>
              </Col>
            </Row> */}
            <Row className="pb-4">
              <Col md="12 py-2">
                <p>...IT-Lösungen, Automotive Engineering, Entwicklung, Verifikation, Security, Automotive, Sicherheitsanalyse</p>
                <p>...Telekommunikationssysteme, Standortvernetzung, Gebäudeüberwachung, Gebäudesicherung, Home-Automation</p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <Container>
        <Row className="row-eq-height">
          <Col md="6" className="p-3 text-white">
            <div className="custom-cage bg-dark-primary p-3">
              <h3>Daten & Kommunikation</h3>
              <p>Heute ist Urbas Daten & Kommunikation in den Bereichen Telekommunikationssysteme,
                Standortvernetzung, Haustechnik, Gebäudeüberwachung,  Gebäudesicherung und Infotainment,
                nicht zuletzt wegen ihrer in die Zukunft ausgerichteten Strategie, ein
                zuverlässiger Partner für Behörden, Verbände sowie namhafte
                Unternehmen des Handels und der Industrie.</p>
              <Row>
                <Col md={{span: 2, offset: 10}}>
                  <div className="custom-cage-primary bg-primary"></div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md="6" className="p-3 text-white">
            <div className="custom-cage bg-dark-primary p-3">
              <h3>Business & Consulting</h3>
              <p>Die zunehmende Digitalisierung, Vernetzung und nicht zuletzt die
                rasante Entwicklungen der Computertechnik und des Internets stellen
                viele Unternehmen nahezu täglich vor neue Herausforderungen.
                Um Unternehmen auch bei der Bewältigung dieser Anforderungen im Zusammenhang
                mit dieser steten Transformation zu Unterstützen, wurde das Unternehmen Urbas
                Business & Consulting gegründet.</p>
              <Row>
                <Col md={{span: 2, offset: 10}}>
                  <div className="custom-cage-primary bg-primary"></div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-4">
        <div className="bg-ultralight">
          <Row>
            {/* <Col md="4" className="p-2">
              <Img
                className="offset-image"
                fluid={data.map.childImageSharp.fluid}
                alt="Gatsby Docs are awesome"></Img>
            </Col> */}
            <Col md="8" className="p-4">
              <p className="col-12 description font-primary p-0">Über uns</p>
              <h2>Das Unternehmen</h2>
              <p>
              Am Anfang stand die Gründung der <b className="font-primary">O.V.H. Urbas</b> im Jahr 1989. Die Hauptgeschäftsfelder lagen in der Entwicklung von Lösungen in den Bereichen Telekommunikation, Netzwerktechnik, EDV/Computerhardware und Gebäudetechnik.
              <br></br>
              1997 wurde das Unternehmen in die heutige <b className="font-primary">URBAS Daten & Kommunikation</b> umbenannt.
              Heute ist URBAS Daten & Kommunikation in den Bereichen Telekommunikationssysteme, Standortvernetzung, Haustechnik, Gebäudeüberwachung, Gebäudesicherung und Infotainment, nicht zuletzt wegen ihrer in die Zukunft ausgerichteten Strategie, ein zuverlässiger Partner für Behörden, Verbände sowie namhafte Unternehmen des Handels und der Industrie.
              <br></br>
              Im Jahre 2003 wurde <b className="col-12 description font-primary p-0">URBAS Business & Consulting</b>, als Unternehmensbereich mit dem Schwerpunkt IT-Dienstleistung, ausgegliedert. Neben der Planung und Umsetzung maßgeschneiderter IT-Lösungen unterstützt Urbas Business & Consulting anspruchsvolle Projekte im Automotive Umfeld.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage
